@font-face {
  font-family: 'Benguiat';
  src: url('../public/fonts/BenguiatStd-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DOS";
  src: url('../public/fonts/PxPlus_IBM_VGA_8x16.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AppleII";
  src: url('../public/fonts/PrintChar21.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


html, body, div#root{
  height:100%;
}

* { 
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  letter-spacing: 0.025em;
  user-select: none;
  /* -webkit-transform: translate3d(0, 0, 0); */
  /* -webkit-perspective: 1000; */
}

.konvajs-content {
  width: 100% !important;
  height: 100% !important;
}

.konvajs-content > canvas {
  width: 100% !important;
  height: 100% !important;
  /* padding: 20px !important; */
  /* width: calc(100% - 40px) !important;
  height: calc(100% - 140px) !important; */
}

.im-fell-dw-pica-regular {
  font-family: "IM Fell DW Pica", serif;
  font-weight: 400;
  font-style: normal;
}

.im-fell-dw-pica-regular-italic {
  font-family: "IM Fell DW Pica", serif;
  font-weight: 400;
  font-style: italic;
}

.animated-character {
  position: relative;

  animation: fade-in 1s ease-in-out;
}

@keyframes fade-in {
  0% { opacity: 0; filter: blur(px);}
  100% { opacity: 1; filter: blur(0px);}
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-reverse {
  from {
    stroke-dashoffset: -100%;
  }
  to {
    stroke-dashoffset: 0;
  }
}